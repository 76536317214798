import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { WizardInitValues } from '../_sharing/models/wizard-init-values.model';
import { ReservationService } from '../_sharing/services/reservation.service';

@Injectable({
    providedIn: 'root',
})
export class NewReservationResolver  {
    constructor(private reservationService: ReservationService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WizardInitValues> | Promise<WizardInitValues> | WizardInitValues {
        if (this.reservationService.wizardInitValues == null) {
            const getReservationSettings$: Observable<WizardInitValues> = this.reservationService.getReservationSettings();
            return forkJoin(getReservationSettings$).pipe(
                map((res) => {
                    let initData: WizardInitValues = res[0];
                    return initData;
                }),
            );
        } else {
            let initData: WizardInitValues = this.reservationService.wizardInitValues;
            return initData;
        }
    }
}
