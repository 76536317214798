import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LangService } from '../_sharing/services/lang.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public hotelName: string;
    public hotelUrl: string;
    public isLanguageChange: boolean;
    public environment: any = environment;
    isClicked = false;
    displayedImage: { src: string; alt: string } | null = null;
    displayedText: string | null = null;

    constructor(public translate: TranslateService, private langService: LangService, private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

    ngOnInit(): void {
        this.hotelName = environment.hotel_name;
        this.hotelUrl = environment.hotel_url;
        this.isLanguageChange = environment.isLanguageChange;
        this.determineDisplayedContent();
    }

    toggle() {
        this.isClicked = !this.isClicked;
    }
    changeLanguage(lang: string) {
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        this.langService.changeNewReservationLanguage(lang).subscribe((data) => this.langService.typeLanguage.next(data.entity));
    }

    private determineDisplayedContent(): void {
        if (this.environment.api_url.includes('demo')) {
            this.displayedImage = { src: 'assets/img/logo-demo.png', alt: 'Demo Logo' };
            this.displayedText = 'DEMO';
        } else if (this.environment.api_url.includes('n33')) {
            this.displayedImage = { src: 'assets/img/logo.png', alt: 'Default Logo' };
        } else {
            this.displayedImage = { src: 'assets/img/logoWhite.png', alt: 'Default Logo' };
        }
    }
}
