import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../auth.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private guest$: Subscription;
    public sub;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.guest$ = this.authService.guest$.subscribe((res) => {
            if (!res) {
                this.login();
            }
        });

        this.sub = this.route.data.subscribe((v) => {
            if (v.isCloseNeeded) {
                window.close();
            }
        });
    }

    login() {
        this.authService.loginWithGuest().subscribe({
            next: (res) => {
                if (res) {
                    const returnUrl = sessionStorage.getItem('returnUrl') || '/reservation/new';
                    // localStorage.clear();
                    sessionStorage.removeItem('returnUrl');
                    // localStorage.removeItem('paymentId');
                    this.router.navigate(['/reservation/new']).then(() => {
                        if (returnUrl === '/vouchers') {
                            this.router.navigate(['/vouchers']);
                        }
                    });
                } else {
                    this.router.navigate(['/problem']);
                }
            },
            error: (err) => {
                console.error(err);
                this.router.navigate(['/problem']);
            },
        });
    }

    ngOnDestroy() {
        this.guest$.unsubscribe();
    }
}
