import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-payment-cart-result',
    templateUrl: './payment-cart-result.component.html',
    styleUrls: ['./payment-cart-result.component.scss'],
})
export class PaymentByCartComponent implements OnInit {
    public environment: any = environment;
    public status = new BehaviorSubject<boolean>(null);

    constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}
}
