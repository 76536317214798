<div class="col-md-9 header">
    <div class="brand-name">
        <a target="_blank" href="{{ hotelUrl }}" class="link-image">
            <img *ngIf="displayedImage" [src]="displayedImage.src" [alt]="displayedImage.alt" class="header-image" />
        </a>
        <h2 *ngIf="displayedText" class="p-2 m-0 text-nowrap">{{ displayedText }}</h2>
        <button *ngIf="environment.voucherOptionActive" mat-button routerLink="/reservation/new" class="menu-btn"><span class="material-symbols-outlined">home</span>Pobyty</button>
        <button *ngIf="environment.voucherOptionActive" mat-button routerLink="/vouchers" class="menu-btn"><span class="material-symbols-outlined">style</span>Podaruj voucher</button>
    </div>
    <div *ngIf="isLanguageChange" class="wrapper-lang">
        <button mat-button [matMenuTriggerFor]="langMenu" (click)="toggle()" class="btn-language">
            {{ translate.currentLang | uppercase }}
            <mat-icon [ngClass]="{ 'rotate-down': !isClicked, 'rotate-up': isClicked }">keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #langMenu="matMenu">
            <button mat-menu-item (click)="changeLanguage('pl')">PL</button>
            <button mat-menu-item (click)="changeLanguage('en')">EN</button>
        </mat-menu>
    </div>
</div>
