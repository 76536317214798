import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, empty } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { AuthResponse } from './models/auth-response.model';
import { LoginGuestData } from './models/login-data.model';
import { Guest } from './models/guest.model';
import { ReservationAuthData } from '../reservation/_sharing/models/reservation-auth-data.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public guest = new BehaviorSubject<Guest>(null);
    private authData = new BehaviorSubject<AuthResponse>(null);
    private reservationAuthData = new BehaviorSubject<ReservationAuthData>(null);
    public guest$ = this.guest.asObservable();
    public authData$ = this.authData.asObservable();
    public reservationAuthData$ = this.reservationAuthData.asObservable();

    constructor(public actRoute: ActivatedRoute, private httpClient: HttpClient, public translate: TranslateService) {}

    loginWithGuest(): Observable<AuthResponse> {
        const GUEST_KEY = 'zrGt4SvZvF6j';
        let checksum = 0;
        const timestamp = String(Date.now());
        for (let i = 0; i < GUEST_KEY.length; i++) {
            const ascii = GUEST_KEY.charCodeAt(i);
            const tsArray = timestamp.split('');
            tsArray.forEach((tsCh) => {
                checksum += ascii * +tsCh * Math.pow(10, i);
            });
        }
        const secret = checksum.toString(16);

        const loginData: LoginGuestData = {
            timestamp,
            secret,
            langKod: this.translate.currentLang,
        };
        return this.loginGuest(loginData).pipe(
            tap((res) => {
                this.authData.next(res);
                const guest = new Guest(res.userId, res.username, res.uuid);
                localStorage.setItem('auth_token', res.uuid);
                localStorage.setItem('userDataAfterLogin', JSON.stringify(guest));
                this.guest.next(guest);
            }),
        );
    }

    clearGuest() {
        this.guest.next(null);
    }

    clearReservationAuthData() {
        this.reservationAuthData.next(null);
    }

    loginGuest(loginData: LoginGuestData): Observable<AuthResponse> {
        return this.httpClient.post<AuthResponse>(`${environment.api_url}login/zalogujGoscia`, loginData);
    }

    getUserId(): Observable<number> {
        return this.guest$.pipe(
            map((res) => {
                return res.userId;
            }),
        );
    }

    storeReservationAuthData(reservationId: string, secret: string) {
        this.reservationAuthData.next({ reservationId, secret });
    }
}
