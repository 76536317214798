import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  typeLanguage = new BehaviorSubject('Zmieniono język na pl');

  constructor(private http: HttpClient) {}

  changeNewReservationLanguage(lang: string): Observable<any> {
    return this.http.get<any>(`login/changeLang?langKod=${lang}`);
  }
}
