<div class="container-payment">
    <div class="wrapper-image-payment">
        <img [src]="environment.paymentImagePath" alt="" srcset="" />
    </div>
    <div class="container-information">
        <div class="container-success">
            <h1 class="container-text">Dziękujemy!</h1>
            <span [style.color]="environment.fontColor" class="container-message"
                >Status Twojej rezerwacji został wysłany na maila podanego przy rezerwacji. Sprawdź swoją pocztę. <br />Możesz również sprawdzić status rezerwacji w Wirtualnej Recepcji. <br />
            </span>
        </div>
        <!-- <div class="container-failed" *ngIf="!status.value">
            <h1 class="container-text">Płatność nie została odnotowana!</h1>
            <span [style.color]="environment.fontColor" class="container-message"> Status rezerwacji został wysłany na maila. Prosimy o zapoznanie się z informacją wysłaną mailowo. </span>
        </div> -->
    </div>
</div>
