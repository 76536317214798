import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { NewReservationResolver } from './reservation/new-reservation/new-reservation-resolver';
import { ProblemComponent } from './problem/problem.component';
import { PaymentByCartComponent } from './reservation/payment-cart-result/payment-cart-result.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'reservations/new',
        redirectTo: '/reservation/new',
        pathMatch: 'full',
    },
    { path: 'login', component: LoginComponent },
    { path: 'problem', component: ProblemComponent },
    { path: 'payment/cart/:status', component: PaymentByCartComponent },
    { path: 'vouchers', canActivate: [AuthGuard], loadChildren: () => import('./vouchers/vouchers.module').then((m) => m.VoucherModule) },
    {
        path: 'reservation/new',
        canActivate: [AuthGuard],
        resolve: { wizardInitValues: NewReservationResolver },
        loadChildren: () => import('./reservation/new-reservation/new-reservation.module').then((m) => m.NewReservationModule),
    },
    {
        path: 'reservation/confirm/:reservationId/:secret/:lang',
        loadChildren: () => import('./reservation/confirm-reservation/confirm-reservation.module').then((m) => m.ConfirmReservationModule),
    },
    {
        path: 'reservation/payment',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./reservation/payment-result/payment-result.module').then((m) => m.PaymentResultModule),
    },
    {
        path: 'payment/result/true/:id',
        loadChildren: () => import('./reservation/payment-result/payment-result.module').then((m) => m.PaymentResultModule),
    },
    {
        path: 'payment/result/false/:id',
        loadChildren: () => import('./reservation/payment-result/payment-result.module').then((m) => m.PaymentResultModule),
    },
    // { path: '**', redirectTo: '/login' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
