import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { Prepayment } from '../models/prepayment.model';
import { LocalChargeInfo, MaxGuests, MaxDate, Param, ParamBoolean, ChildrenInfo, WizardInitValues } from '../models/wizard-init-values.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NewReservation } from '../models/new-reservation.model';
import { Company } from '../../_sharing/models/company.model';
import { map, tap } from 'rxjs/operators';
import { roomToConfirm } from '../models/room-to-confirm.model';
import { environment } from 'src/environments/environment';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ReservationService {
    public serviceDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public serviceData$: Observable<any> = this.serviceDataSubject.asObservable();
    public searchParams = null;
    // public withOffers = new BehaviorSubject<boolean>(false);
    public getDataCompany = new BehaviorSubject([]);
    public oplataMiejscowa = new BehaviorSubject(false);
    public dataFormCompany$: Observable<Company[]>;
    public isApart = new BehaviorSubject<boolean>(false);
    public environment;
    public wizardInitValues: WizardInitValues = null;
    public listOfAddOns: any = new BehaviorSubject([{}]);
    public voucherActive = new BehaviorSubject({});

    constructor(private http: HttpClient) {
        // this.withOffers.next(environment.withOffers);
    }

    getMaximumStayGuestNumber(): Observable<MaxGuests> {
        return this.http.get<MaxGuests>('portal/rezerwacja/getMaksymalnaIloscGosci');
    }

    getMaximumDateTo(): Observable<MaxDate> {
        return this.http.get<MaxDate>('portal/rezerwacja/getMaksymalnaDataRezerwacji ');
    }

    getDefaultArrivalHour(): Observable<Param> {
        return this.http.get<Param>('param/getDomyslnaGodzinaPrzyjazdu');
    }

    getDefaultDepartureHour(): Observable<Param> {
        return this.http.get<Param>('param/getDomyslnaGodzinaWyjazdu');
    }

    getCreditCardConfirmationParam(): Observable<ParamBoolean> {
        return this.http.get<ParamBoolean>('param/portal/getPortalPotwierdzenieRezerwacjiPrzezNrKarty');
    }

    getOnlinePaymentConfirmationParam(): Observable<ParamBoolean> {
        return this.http.get<ParamBoolean>('param/portal/getPortalPotwierdzenieRezerwacjiPrzezPlatnoscOnline');
    }

    getBankNumberConfirmationParam(): Observable<ParamBoolean> {
        return this.http.get<ParamBoolean>('param/portal/getPortalPotwierdzenieRezerwacjiPrzezNumerKonta');
    }

    portalgetPortalObslugaMultipokoi(): Observable<ParamBoolean> {
        return this.http.get<ParamBoolean>('param/portal/getPortal_VR_BE_ObslugaMultipokoi');
    }

    getRoomTypes(dateStart?, dateEnd?, peopleNumber?, isApart?, childrenConfig?, polozenieId?) {
        if (!dateStart || !dateEnd || !moment(dateStart).isValid() || !moment(dateEnd).isValid()) {
            return;
        }
        const peopleNumberString = peopleNumber ? `&ileOsob=${peopleNumber}` : '';
        const polozenieString = polozenieId ? `&polozenieId=${polozenieId}` : '';
        let urlBase = '';
        let configString = '';
        if (childrenConfig && childrenConfig.length >= 1) {
            configString = childrenConfig.map((prog) => `&${prog.id}=${prog.ilosc}`).join('');
        }
        if (this.wizardInitValues['multirooms'] === true && environment.api_url.includes('feelwell')) {
            urlBase = 'portal/mk/rezerwacja/getDostepneTypyPokoi';
        } else if (this.wizardInitValues['multirooms'] === false && environment.api_url.includes('feelwell')) {
            urlBase = '/portal/rezerwacja/getDostepneTypyPokoi';
        } else {
            urlBase = isApart !== true ? 'portal/rezerwacja/getDostepneTypyPokoi' : 'portal/rezerwacja/getDostepnePokoje';
        }

        let url = `${urlBase}?dataOd=${dateStart.format('YYYY-MM-DD')}&dataDo=${dateEnd.format('YYYY-MM-DD')}`;

        url += configString !== '' ? configString : peopleNumberString;
        url += polozenieString;

        try {
            return this.http.get<any>(url);
        } catch (error) {
            console.error('Błąd przy wysyłaniu żądania:', error);
        }
    }

    checkRoomPrice(dateStart, dateEnd, roomType, adultsNumber, breakFast, childrenConfig = [], polozenieId = '', kodRabatowy = '') {
        let url = '';
        let childrenConfigString = '';

        if (childrenConfig.length >= 1) {
            childrenConfigString = childrenConfig.map((prog) => `&${prog.id}=${prog.ilosc}`).join('');
        }

        const polozenieString = polozenieId ? `&polozenieId=${polozenieId}` : '';
        const kodRabatowyString = kodRabatowy ? `&kodRabatowy=${kodRabatowy}` : '';

        const multiroomsPath = this.wizardInitValues['multirooms'] && environment.api_url.includes('feelwell') ? 'mk/' : '';

        const baseParams = `?dataOd=${moment(dateStart).local().format('YYYY-MM-DD')}` + `&dataDo=${moment(dateEnd).local().format('YYYY-MM-DD')}` + `&pokojtypId=${roomType}` + `&ileDoroslych=${adultsNumber}` + `&czySniadania=${breakFast}`;

        url = `portal/${multiroomsPath}rezerwacja/getCenyPokoi${baseParams}${childrenConfigString}${polozenieString}${kodRabatowyString}`;

        return this.http.get<any>(url);
    }

    checkRoomPriceWithOffers(dateStart, dateEnd, roomType, adultsNumber, breakFast, childrenConfig = [], polozenieId = null, kodRabatowy = null) {
        let url = `portal/rezerwacja/getCenyPokoi${childrenConfig.length >= 1 ? 'ZOfertami' : ''}?dataOd=${moment(dateStart).local().format('YYYY-MM-DD')}&dataDo=${moment(dateEnd)
            .local()
            .format('YYYY-MM-DD')}&pokojtypId=${roomType}&ileDoroslych=${adultsNumber}&czySniadania=${breakFast}`;

        if (childrenConfig.length >= 1) {
            const configString = childrenConfig.map((prog) => `&${prog.id}=${prog.ilosc}`).join('');
            url += configString;
        }

        if (polozenieId) {
            url += `&polozenieId=${polozenieId}`;
        }

        if (kodRabatowy) {
            url += `&kodRabatowy=${kodRabatowy}`;
        }

        return this.http.get<any>(url);
    }

    getPrepaymentAmount(roomPrice: number, allAsortsPrice: number): Observable<Prepayment> {
        return this.http.get<Prepayment>('portal/rezerwacja/getKwotaPrzedplatyDlaRezerwacji?wartPobyt=' + roomPrice + '&wartUslugi=' + allAsortsPrice);
    }

    getLocalChargeInfo(): Observable<LocalChargeInfo> {
        return this.http.get<LocalChargeInfo>('text/getPortalOplataMiejscowaInfo');
    }

    getChildrenInfo(): Observable<ChildrenInfo> {
        return this.http.get<LocalChargeInfo>('text/getPortalDzieciUwaga');
    }

    registerNewReservation(model: NewReservation): Observable<any> {
        return this.http.post<any>('portal/rezerwacja/dodajRezerwacje', model);
    }

    getCompanyByNip(nip: string): Observable<Company> {
        return this.http.get<Company>(`firma/findFirmaInRegonDatabase?firmaNip=${nip}`);
    }

    getReservationToConfirm(reservationId: string, secret: string, lang: string): Observable<roomToConfirm> {
        return this.http.get<roomToConfirm>(`lite/reservation/getReservationToConfirm?rezerwacjaId=${reservationId}&secret=${secret}&langKod=${lang}`);
    }

    getGuestAgeClassList() {
        return this.http.get<any>('portal/getGuestAgeClassList');
    }
    getLocalizationList() {
        return this.http.get<any>('pokoj/getPolozeniaList').pipe(
            map((polozeniaList) => {
                const apiUrl = environment.api_url;
                if (apiUrl.indexOf('arche') != -1) {
                    return polozeniaList.filter(
                        (polozenie) =>
                            polozenie.polozenieId == 6 ||
                            polozenie.polozenieId == 8 ||
                            polozenie.polozenieId == 11 ||
                            polozenie.polozenieId == 16 ||
                            polozenie.polozenieId == 10 ||
                            polozenie.polozenieId == 12 ||
                            polozenie.polozenieId == 17 ||
                            polozenie.polozenieId == 9 ||
                            polozenie.polozenieId == 13 ||
                            polozenie.polozenieId == 19 ||
                            polozenie.polozenieId == 20 ||
                            polozenie.polozenieId == 18,
                    );
                } else if (apiUrl.indexOf('dreamaparts') != -1) {
                    return polozeniaList.filter((polozenie) => polozenie.polozenieId == 5 || polozenie.polozenieId == 6 || polozenie.polozenieId == 7 || polozenie.polozenieId == 8 || polozenie.polozenieId == 9);
                }

                return polozeniaList;
            }),
        );
    }
    getUstawieniaBE() {
        return this.http.get<any>('ustawienia/getUstawieniaBE');
    }

    getReservationSettingsNew() {
        const childrenConfiguration$: Observable<any> = this.getGuestAgeClassList();
        const ustawieniaBE$: Observable<MaxGuests> = this.getUstawieniaBE();

        let fork = [ustawieniaBE$, childrenConfiguration$];
        if (environment['localizationSelect']) {
            const localizationList$: Observable<any> = this.getLocalizationList();
            fork.push(localizationList$);
        }

        return forkJoin(fork).pipe(
            map((res: any) => {
                let childrenConfigSorted = [];
                res[1].forEach(function (value) {
                    childrenConfigSorted[value.classId] = value;
                });
                let initData: WizardInitValues = {
                    maxGuestsNumber: res[0].maksymalnaIloscGosci,
                    maxChildsNumber: res[0].portal_MaksymalnaIloscDzieci,
                    maxDate: res[0].maksymalnaDataRezerwacji,
                    arrivalTime: res[0].domyslnaGodzinaPrzyjazdu,
                    departureTime: res[0].domyslnaGodzinaWyjazdu,
                    localChargeInfo: res[0].portalOplataMiejscowaInfo ? res[0].portalOplataMiejscowaInfo : null,
                    paymentMethods: {
                        paymetByOnline: res[0].portal_PotwierdzenieRezerwacjiPrzezPlatnoscOnline,
                        paymetByCard: res[0].portal_PotwierdzenieRezerwacjiPrzezNrKarty,
                        paymetByTransfer: res[0].portal_PotwierdzenieRezerwacjiPrzezNumerKonta,
                    },
                    childrenConfiguration: childrenConfigSorted,
                    showOffers: res[0].portal_CzyPokazywacOferty,
                    localizationList: res[fork.length - 1],
                };

                // this.withOffers.next(initData.showOffers);

                if (environment['localizationSelect']) {
                    initData.localizationList = res[4];
                }
                this.wizardInitValues = initData;
                return initData;
            }),
        );
    }

    getReservationSettings() {
        const maxGuests$: Observable<MaxGuests> = this.getMaximumStayGuestNumber();
        const maxDate$: Observable<MaxDate> = this.getMaximumDateTo();
        const arrivalTime$: Observable<Param> = this.getDefaultArrivalHour();
        const departureTime$: Observable<Param> = this.getDefaultDepartureHour();
        const localChargeInfo$: Observable<LocalChargeInfo> = this.getLocalChargeInfo();
        const paymetByOnline$: Observable<ParamBoolean> = this.getOnlinePaymentConfirmationParam();
        const paymetByCard$: Observable<ParamBoolean> = this.getCreditCardConfirmationParam();
        const paymetByTransfer$: Observable<ParamBoolean> = this.getBankNumberConfirmationParam();
        const childrenConfiguration$: Observable<any> = this.getGuestAgeClassList();
        let multirooms$: Observable<ParamBoolean> = of(null);
        if (environment.api_url.includes('feelwell')) {
            multirooms$ = this.portalgetPortalObslugaMultipokoi();
        }
        // const showOffers$: Observable<any> = this.getShowOffers();
        let fork = [
            forkJoin([maxGuests$, maxDate$, arrivalTime$, departureTime$, childrenConfiguration$]),
            localChargeInfo$,
            forkJoin([paymetByOnline$, paymetByCard$, paymetByTransfer$, multirooms$]),
            // showOffers$,
        ];

        if (environment['localizationSelect']) {
            const localizationList$: Observable<any> = this.getLocalizationList();
            fork.push(localizationList$);
        }

        return forkJoin(fork).pipe(
            map((res: any) => {
                let childrenConfigSorted = [];
                res[0][4].forEach(function (value) {
                    childrenConfigSorted[value.classId] = value;
                });
                let initData: WizardInitValues = {
                    maxGuestsNumber: res[0][0].maxIloscGosci,
                    maxChildsNumber: res[0][0].maxIloscGosci - 1,
                    maxDate: res[0][1].maxData,
                    arrivalTime: res[0][2].paramWartosc,
                    departureTime: res[0][3].paramWartosc,
                    localChargeInfo: res[2] ? res[2].textString : null,
                    paymentMethods: {
                        paymetByOnline: res[2][0].paramWartosc,
                        paymetByCard: res[2][1].paramWartosc,
                        paymetByTransfer: res[2][2].paramWartosc,
                    },
                    childrenConfiguration: childrenConfigSorted,
                    showOffers: environment.offersOptionActive,
                    localizationList: res[3] ? res[3] : [],
                    multirooms: res[3] && res[3][3] && res[3][3].paramWartosc ? res[3][3].paramWartosc : null,
                };

                // this.withOffers.next(initData.showOffers);

                if (environment['localizationSelect']) {
                    initData.localizationList = res[3];
                }
                this.wizardInitValues = initData;
                return initData;
            }),
        );
    }

    getParamValueByName(paramName: string) {
        return this.http.get<any>('param/getParamValueByName?paramNazwa=' + paramName);
    }

    getShowOffers() {
        return this.http.get<any>('param/getParamValueByName?paramNazwa=Portal_CzyPokazywacOferty');
    }

    getAddonsWrListByDate(dataOd: string, dataDo: string, pokojtypId: number, polozenieId?: number) {
        const addonsUrl = `portal/rezerwacja/getDodatekWrList?dataOd=${dataOd}&dataDo=${dataDo}&pokojtypId=${pokojtypId}`
                          + (polozenieId ? `&polozenieId=${polozenieId}` : '');
    
        this.http.get<any>(addonsUrl).subscribe((addOns) => {
            this.listOfAddOns.next(addOns);
        });
    }

    getOplataKlimatycznaParams() {
        const url = `oplataklimat/getOplataKlimatycznaParams`;
        return this.http.get(url);
    }
    getKwotaOplaty(startDate: Moment, endDate: Moment, adultNumber: Number) {
        const sDate = moment(startDate).format('YYYY-MM-DD');
        const eDate = moment(endDate).format('YYYY-MM-DD');
        const url = `oplataklimat/getKwotaOplaty?dataOd=${sDate}&dataDo=${eDate}&0=${adultNumber}`;

        return this.http.get(url);
    }

    fetchFormData(): Observable<AbstractControl[]> {
        return this.getDataCompany.asObservable();
    }
    getDataVoucher(name: string) {
        const url = `portal/voucher/getVoucherInfo?voucherKod=${name}`;

        return this.http.get(url);
    }
}
