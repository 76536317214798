import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import config from '../../package.json';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent): void {
      location.reload();
    }
  
    public appVersion: any;
    public copyrighttext: string = '';
    public environment: any = environment;
    public addressLine1?: string = '';
    public addressLine2?: string = '';

    constructor(public translate: TranslateService, public swUpdate: SwUpdate) {
        translate.addLangs(['en', 'pl']);
        translate.setDefaultLang('pl');
        translate.use('pl');
        this.copyrighttext = `Copyright ${moment().format('YYYY')} Ekoncept`;
    }

    ngOnInit(): void {
        this.checkHotelEnv();
        this.appVersion = config.version;
        if (environment.gtm === true) {
            this.appendGTM();
        }
        if (environment.layoutSettings.footer.addressLine1 && environment.layoutSettings.footer.addressLine2) {
            this.asignToVariables(environment);
        } else {
            this.addressLine1 = '';
            this.addressLine2 = '';
        }
        this.unregisterServiceWorkers();
        history.pushState(null, '', window.location.href);
    }
    appendGTM() {
        let node = document.createElement('script');
        node.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.gtmtag}');`; // sets the source (insert url in between quotes)
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
        //---------------
        let iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmtag}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        document.getElementsByTagName('body')[0].appendChild(iframe);
    }
    getWindowWidth(): number {
        console.log(window.innerWidth);
        return window.innerWidth;
    }

    asignToVariables(dataEnv) {
        this.addressLine1 = dataEnv.layoutSettings.footer.addressLine1;
        this.addressLine2 = dataEnv.layoutSettings.footer.addressLine2;
    }
    checkHotelEnv() {
        if (environment.api_url.includes('montownia') || environment.api_url.includes('test')) {
            document.title = 'Montownia - Rezerwacje';
            let favicon = document.querySelector('link[rel="icon"]');
            favicon['href'] = `${environment.favicon_url} `;
        } else {
            document.title = 'Booking Engine';
        }
    }

    unregisterServiceWorkers(): void {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => {
                    console.log('Starting to unregister service workers');
                    registrations.forEach((registration) => {
                        registration.unregister().then((success) => {
                            if (success) {
                                console.log('Service worker unregistered');
                            }
                        });
                    });
                })
                .catch((err) => {
                    console.error('Error during service worker unregistration:', err);
                });
        }
    }
}
