import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ReservationService } from '../reservation/_sharing/services/reservation.service';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private authService: AuthService, private reservationService: ReservationService, private router: Router, private actRoute: ActivatedRoute) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.guest$.pipe(
            take(1),
            map((res) => {
                const isAuth = !!res;
                if (isAuth) {
                    return true;
                } else {
                    sessionStorage.setItem('returnUrl', state.url);
                    if (next.queryParams) {
                        this.reservationService.searchParams = next.queryParams;
                    }
                    return this.router.createUrlTree(['/login']);
                }
            }),
        );
    }
}
