import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { take, exhaustMap, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes('i18n')) {
            return next.handle(request);
        }
        const apiUrl = environment.api_url;

        return this.authService.guest$.pipe(
            take(1),
            exhaustMap((guest) => {
                if (guest) {
                    return this.handleAuthorizedRequest(request, next, apiUrl, guest.uuid);
                }
                return this.authService.reservationAuthData$.pipe(
                    take(1),
                    exhaustMap((reservation) => {
                        if (reservation?.reservationId) {
                            return this.handleRequestWithModifiedUrl(request, next, apiUrl);
                        }
                        return this.handleRequestWithoutModification(request, next);
                    })
                );
            })
        );
    }

    private handleAuthorizedRequest(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        apiUrl: string,
        token: string
    ): Observable<HttpEvent<unknown>> {
        const modifiedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
            url: apiUrl + request.url,
        });

        return next.handle(modifiedRequest).pipe(
            catchError((err) => {
                const message = err.status === 401
                    ? this.translate.instant('sessionExpiredAlert')
                    : this.translate.instant('globalError');

                if (err.status === 401) {
                    this.router.navigate(['/login']);
                }

                this.handleCommonErrors(err, message);
                return throwError(err);
            })
        );
    }

    private handleRequestWithModifiedUrl(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        apiUrl: string
    ): Observable<HttpEvent<unknown>> {
        const modifiedRequest = request.clone({ url: apiUrl + request.url });

        return next.handle(modifiedRequest).pipe(
            catchError((err) => {
                this.handleCommonErrors(err, 'An unknown error occurred!');
                return throwError(err);
            })
        );
    }

    private handleRequestWithoutModification(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err) => {
                this.handleCommonErrors(err, 'An unknown error occurred!');
                return throwError(err);
            })
        );
    }

    private handleCommonErrors(err: any, message: string) {
        const errorMessage = err.error?.message || message;

        this.snackBar.open(errorMessage, '', {
            duration: 3000,
            panelClass: ['snack-bar-alert'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    }
}
