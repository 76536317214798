function generateImageUrls(prefix: string, count: number): string[] {
    const urls = [];
    for (let i = 1; i <= count; i++) {
        urls.push(`assets/img/rooms/${prefix}_${i}.jpg`);
    }
    return urls;
}
export const environment = {
    production: true,
    url: '',
    api_url: 'https://zatoka-booking.virtualreception.pl/api/',
    hotel_name: 'Hotel Zatoka',
    privacy_policy: '',
    hotel_rules: 'https://virtualreception.pl/docs/zatoka',
    hotel_url: 'https://zatoka.pl/',
    instagram_url: 'https://www.instagram.com/hotel_zatoka/',
    facebook_url: 'https://www.facebook.com/people/Hotel-Zatoka/100054606002511/',
    linkedin_url: '',
    asorts: false,
    isBreakfastRequired: false,
    isBreakfastDefaultSelected: false,
    breakfastCheckbox: true,
    localizationSelect: false,
    rabatInput: true,
    isApart: false,
    minimalStayDays: 1,
    // opcjonalne funkcje do włączenia parametrem
    voucherOptionActive: false,
    offersOptionActive: true,
    breakfastOptionActive: true,
    localChargeOptionActive: false,
    // 
    localCharge: false,
    paymentImagePath: 'assets/img/background.jpg',
    isLanguageChange: false,
    gtm: true,
    gtmtag: 'GTM-KP459W66',
    hiddenIcons: false,
    favicon_url: '',
    layoutSettings: {
        footer: {
            addressLine1: 'Hotel Zatoka',
            addressLine2: 'ul. Trakt Św. Wojciecha 149, Gdańsk',
        },
    },
    messageForNoRooms: 'Brak pokoi w wybranym terminie',
    roomTypeToImagesMap: {
        STD: generateImageUrls('STD_2os', 3),
        DLX: generateImageUrls('DLX_2os', 3),
        'STD 3': generateImageUrls('STD_3', 3),
        'DLX 3': generateImageUrls('DLX_3', 3),
        'DLX 4': generateImageUrls('DLX_4', 4),
    },
    cookiesOptionActive: true,
    offersSettings: {
        bezzwrotna: [
            {
                PL: {
                    offerName: 'Oferta bezzwrotna',
                    grossPrice: 0,
                    adultNumber: 0,
                    numberOfNights: 0,
                    description: (grossPrice, adultNumber, numberOfNights, breakfast?) =>
                        `<div class="container_single_offer">
                        <div class="container-inclusions"><h4 class="offer-title">Oferta obejmuje:</h4><ul class="offer-list-popup"><li class="room-card__description-row">nocleg w komfortowym pokoju</li> ${
                            breakfast ? '<li class="room-card__description-row">śniadania w formie bufetu</li>' : ''
                        }<li class="room-card__description-row">nieograniczony dostęp do bezpłatnego WiFi</li><li class="room-card__description-row">bezpłatny parking bez konieczności rezerwacji miejsca</li><li class="room-card__description-row">zestaw powitalny w pokoju: kawa, herbata, woda butelkowana</li><li class="room-card__description-row">zestaw kosmetyków: żel pod prysznic/ szampon/ mydło do rąk</li></ul><h4 class="offer-title">Oferta nie obejmuje:</h4><ul class="offer-list-popup">${
                            breakfast ? '' : '<li class="room-card__description-row">wyżywienia</li>'
                        }<li class="room-card__description-row">podatku miejskiego w cenie 2,80 zł/osobę</li></ul></div><div class="container-cancellation"><p> <h4 class="offer-title">Anulowanie rezerwacji:</h4>W przypadku odwołania rezerwacji lub niepojawienia się w obiekcie naliczona zostanie całkowita kwota rezerwacji.</p></div><div class="container-modification"><p><h4 class="offer-title">Zmiany w rezerwacji:</h4>Do 2 dni przed datą przyjazdu można zmienić termin rezerwacji. W sprawie zmiany terminu pobytu należy skontaktować się z Recepcją. Zmiana terminu jest możliwa po wcześniejszym potwierdzeniu dostępności pokoi w wybranym terminie. Jeżeli ceny obowiązujące w nowo wybranym terminie będą wyższe, wówczas należy dopłacić różnicę. Jeżeli cena w nowo wybranym terminie będzie niższa, wówczas Hotel nie zwraca różnicy. Z prawa do zmiany terminu rezerwacji można skorzystać jeden raz.</p></div><div class="container-prepayment"><p><h4 class="offer-title">Przedpłata:</h4>Rezerwację należy opłacić za pośrednictwem strony internetowej Hotelu lub podając dane ważnej karty kredytowej w celu zagwarantowania rezerwacji.  Po podaniu danych karty kredytowej Hotel w dowolnym momencie dokona preautoryzacji (blokady) środków na koncie do wysokości wartości rezerwacji a transakcja zostanie dopełniona na miejscu w Hotelu z fizycznym użyciem podanej do zagwarantowania rezerwacji karty. Po wcześniejszym poinformowaniu Hotelu przez Gościa możliwe jest również opłacenie rezerwacji poprzez dokonanie przelewu tradycyjnego: należy skontaktować się z Recepcją Hotelu w celu uzyskania danych do przelewu.Jeżeli rezerwacja nie zostanie opłacona lub nie będzie można dokonać preautoryzacji (blokady) środków na karcie, wówczas Hotel zastrzega sobie prawo do anulowania rezerwacji. 
                        </p></div><div class="container-breakfast"><p><h4 class="offer-title">Śniadanie można nabyć w cenie:</h4><ul class="offer-list-popup">
                        <li class="room-card__description-row">45 zł/ osoba dorosła</li>
                        <li class="room-card__description-row">25 zł/ dziecko w wieku od 7 do 12 lat włącznie</li>
                        <li class="room-card__description-row">0 zł/ dziecko do 6 lat włącznie</li>
                        </ul></p>
                        <p><h4 class="offer-title">Pobyt dzieci:</h4><ul class="offer-list-popup">
                        ${
                            breakfast
                                ? '<li class="room-card__description-row">śniadanie dla dziecka w wieku do 6 lat włącznie jest gratis</li><li class="room-card__description-row">śniadanie dla dziecka w wieku od 7 do 12 lat włącznie jest w cenie 25 zł</li>'
                                : ''
                        }
                        <li class="room-card__description-row">łóżeczko z pościelą dla dziecka do 3 lat gratis – o dostępność prosimy pytać Recepcję</li>
                        <li class="room-card__description-row">pobyt dzieci w wieku do 6 lat włącznie gratis, jeśli nie zajmują dodatkowych łóżek</li>
                        <li class="room-card__description-row">pobyt dzieci w wieku do 6 lat, jeśli zajmują osobne łóżko – płatny 100%</li>
                        <li class="room-card__description-row">pobyt dzieci powyżej 6 lat płatny 100%</li>
                        </ul></p></div><div class="container-pets"><h4 class="offer-title">Pobyt pupila </h4> Pobyt pupila jest dodatkowo płatny: 40 zł/dobę.</p></div><div class="container-checkin"><p><h4 class="offer-title">Doba hotelowa</h4> Zaczyna się o godzinie 14:00 a kończy o godzinie 12:00.</p>${
                            breakfast
                                ? '<p><h4 class="offer-title">Śniadania serwowane są w godzinach:</h4> <ul class="offer-list-popup"> <li class="room-card__description-row">od poniedziałku do piątku od 7:00 do 10:30</li><li class="room-card__description-row">w niedziele i święta od 7:30 do 11:00</li></ul></p>'
                                : ''
                        }</div>
                        </div>`,
                },
            },
            {
                EN: {
                    offerName: 'Oferta bezzwrotna',
                    grossPrice: 0,
                    adultNumber: 0,
                    numberOfNights: 0,
                    description: (
                        grossPrice,
                        adultNumber,
                        numberOfNights,
                    ) => `<div class="container_single_offer"><div class="container_image"><img src="assets/img/rooms/oferta_bezzwrotna.jpg"></div><div class="container_background"><div class="container-offer"><h3 class="type-offer">Non-refundable offer</h3><span class="price-description">Lower price with no return policy</span><span class="type-room">Non Classic</span></div><div class="container-price"><p>${grossPrice} PLN</p><p>${adultNumber} guests, ${numberOfNights} night/s</p><div class="reservation mat-raised-button">Rezerwuj</div></div></div><div class="container-description-room"><p class="description">If you are looking for comfortable accommodation in Gdańsk 
          at the best price. Book your stay on our website.
          Welcome to Montownia Lofts & Experience!
          </p><div class="description"><span>Offer includes:</span><ul><li>comfortable accommodation in a unique loft with an area almost twice bigger than a typical hotel   
          room
          </li><li>own fully equipped kitchenette</li><li>coffee machine in the room with the function of preparing hot chocolate</li><li>unlimited access to WIFI</li><li>access to the laundry in the Facility</li><li>for children access to the Kids Room in the Facility</li></ul></div><div class="additional-information"><div class="container-information"><span>Additional information:</span><span>Arrival from 16:00. Departure until 11:00</span></div></div><div class="additional-information"><div class="container-information"><span>Pet policy:</span><span>Pets are accepted. Additional cost is 100 PLN a day per pet.</span><span></span></div></div><div class="additional-information"><div class="container-information"><span>Cancellation conditions:</span><span>This offer is non-refundable, payment for the reservation is required in advance by the online payment system. In case of cancellation, modifications or not showing up at the arrival date no refunds will be made.</span></div></div><div class="additional-information"><div class="container-information"><span>Paid extra:</span><span>transfer from the airport + 100 pln</span><span>parking (outdoor) + 100 pln /night</span></div></div></div></div>`,
                },
            },
        ],
        elastyczna: [
            {
                PL: {
                    offerName: 'Oferta elastyczna',
                    grossPrice: 0,
                    adultNumber: 0,
                    numberOfNights: 0,
                    description: (grossPrice, adultNumber, numberOfNights, breakfast?) =>
                        `<div class="container_single_offer">
                        <div class="container-inclusions"><h4 class="offer-title">Oferta obejmuje:</h4><ul class="offer-list-popup"><li class="room-card__description-row">nocleg w komfortowym pokoju</li>  ${
                            breakfast ? '<li class="room-card__description-row">śniadania w formie bufetu</li>' : ''
                        }<li class="room-card__description-row">nieograniczony dostęp do bezpłatnego WiFi</li><li class="room-card__description-row">bezpłatny parking bez konieczności rezerwacji miejsca</li><li class="room-card__description-row">zestaw powitalny w pokoju: kawa, herbata, woda butelkowana</li><li class="room-card__description-row">zestaw kosmetyków: żel pod prysznic/ szampon/ mydło do rąk</li></ul><h4 class="offer-title">Oferta nie obejmuje:</h4><ul class="offer-list-popup">${
                            breakfast ? '' : '<li class="room-card__description-row">wyżywienia</li>'
                        }<li class="room-card__description-row">podatku miejskiego w cenie 2,80 zł/osobę</li></ul></div><div class="container-cancellation"><p> <h4 class="offer-title">Anulowanie rezerwacji:</h4>Rezerwację można bezpłatnie anulować do godz. 14:00 w dniu przyjazdu. W przypadku odwołania rezerwacji po godzinie 14:00 w dniu przyjazdu lub niepojawienia się w obiekcie zostanie naliczona kwota równa cenie za pierwszą dobę pobytu.</p></div><div class="container-modification"><p><h4 class="offer-title">Zmiany w rezerwacji:</h4> Do godziny 14:00 w dniu przyjazdu można zmienić termin rezerwacji. W sprawie zmiany terminu pobytu należy skontaktować się z Recepcją. Zmiana terminu jest możliwa po wcześniejszym potwierdzeniu dostępności pokoi w wybranym terminie. Cena rezerwacji zostanie zmieniona zgodnie z ceną obowiązującą w nowo wybranym terminie.</p></div><div class="container-prepayment"><p><h4 class="offer-title">Przedpłata:</h4> Przedpłata nie jest wymagana, z wyjątkiem wybranych terminów. Jeśli w wybranym terminie będzie wymagana przedpłata, wówczas Hotel poinformuje o tym w osobnej wiadomości e-mail lub telefonicznie. Podana do zagwarantowania rezerwacji karta kredytowa może zostać obciążona nie wcześniej, niż po godzinie 14:00 w dniu przyjazdu.</p></div><div class="container-breakfast"><p><h4 class="offer-title">Śniadanie można nabyć w cenie:</h4><ul class="offer-list-popup">
                        <li class="room-card__description-row">45 zł/ osoba dorosła</li>
                        <li class="room-card__description-row">25 zł/ dziecko w wieku od 7 do 12 lat włącznie</li>
                        <li class="room-card__description-row">0 zł/ dziecko do 6 lat włącznie</li>
                        </ul></p></div><div class="container-children"><p><h4 class="offer-title">Pobyt dzieci:</h4><ul class="offer-list-popup">
                        ${
                            breakfast
                                ? '<li class="room-card__description-row">śniadanie dla dziecka w wieku do 6 lat włącznie jest gratis</li><li class="room-card__description-row">śniadanie dla dziecka w wieku od 7 do 12 lat włącznie jest w cenie 25 zł</li>'
                                : ''
                        }
                        <li class="room-card__description-row">łóżeczko z pościelą dla dziecka do 3 lat gratis – o dostępność prosimy pytać Recepcję</li>
                        <li class="room-card__description-row">pobyt dzieci w wieku do 6 lat włącznie gratis, jeśli nie zajmują dodatkowych łóżek</li>
                        <li class="room-card__description-row">pobyt dzieci w wieku do 6 lat, jeśli zajmują osobne łóżko – płatny 100%</li>
                        <li class="room-card__description-row">pobyt dzieci powyżej 6 lat płatny 100%</li>
                        </ul></p></div><div class="container-pets"><p><h4 class="offer-title">Pobyt pupila </h4> Pobyt pupila jest dodatkowo płatny: 40 zł/dobę.</p></div><div class="container-checkin"><p><h4 class="offer-title">Doba hotelowa</h4> Zaczyna się o godzinie 14:00 a kończy o godzinie 12:00.</p>${
                            breakfast
                                ? '<p><h4 class="offer-title">Śniadania serwowane są w godzinach:</h4> <ul class="offer-list-popup"> <li class="room-card__description-row">od poniedziałku do piątku od 7:00 do 10:30</li><li class="room-card__description-row">w niedziele i święta od 7:30 do 11:00</li></ul></p>'
                                : ''
                        }</div>
                        </div>`,
                },
            },
            {
                EN: {
                    offerName: 'Flexible offer',
                    grossPrice: 0,
                    adultNumber: 0,
                    numberOfNights: 0,
                    description: (grossPrice, adultNumber, numberOfNights) =>
                        `<div class="container_single_offer"><div class="container_image"><img src="assets/img/rooms/oferta_elastyczna.jpg"></div><div class="container_background"><div class="container-offer"><h3 class="type-offer">Flexible offer</h3></div><div class="container-price"><p>${grossPrice} PLN</p><p>${adultNumber} guests, ${numberOfNights} night/s</p><div class="reservation mat-raised-button">Rezerwuj</div></div></div><div class="container-description-room"><p class="description">Do you want to visit Gdańsk? Accommodation at Montownia
            Lofts & Experience is an excellent choice. If you change your plans 
            we offer free cancellation.
            </p><div class="description"><span>Offer includes:</span><ul><li>comfortable accommodation in a unique loft with an area almost twice bigger than a typical hotel room</li><li>own fully equipped kitchenette</li><li>coffee machine in the room with the function of preparing hot chocolate</li><li>unlimited access to WIFI</li><li>access to the laundry in the Facility</li><li>for children access to the Kids Room in the Facility</li></ul></div><div class="additional-information"><div class="container-information"><span>Additional information:</span><span>Arrival from 16:00. Departure until 11:00.</span></div></div><div class="additional-information"><div class="container-information"><span>Pet policy:</span><span>Pets are accepted.</span><span>Additional cost is 100 PLN a day per pet.</span></div></div><div class="additional-information"><div class="container-information"><span>Cancellation conditions:</span><span>This reservation can be cancelled free of charge up to 6 p.m. on the day of arrival. If cancellation is submitted after this time or guest will not show at all, the guest will be charged the first night. It is required to provide credit card information.</span></div></div><div class="additional-information"><div class="container-information"><span>Paid extra:</span><span>transfer from the airport + 100 pln</span></div></div></div></div>`,
                },
            },
        ],
        buttonColor: '#008781',
    },
};
